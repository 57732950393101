import React, { ChangeEvent } from 'react'
import { TextFieldProps } from '@material-ui/core'
import { Field as FinalField, FieldRenderProps } from 'react-final-form'
import { useTranslation } from 'react-i18next'


interface OwnProps {
  name: string;
  component: any;
  children?: any;
}
interface YupI18nError {
  key: string;
  values: { [key: string]: string | number };
}

export type Error = string | YupI18nError;

type FieldValue = string | number | boolean | object

type FieldProps = TextFieldProps | any;

type Props = OwnProps & FieldProps;

export const Field = ({ name, component, children, onChange, type, ...inputProps }: Props) => {
  const { t } = useTranslation()
  const Input = component
  const getError = (error: Error): string => {
    if (typeof error === 'string') {
      return t((error as string))
    }
    return t((error as YupI18nError).key, (error as YupI18nError).values)
  }

  return (
    <FinalField name={name} type={type}>
      {({ input, meta }: FieldRenderProps<FieldValue>) => (
        <Input
          {...inputProps}
          type={type}
          error={(meta.touched || meta.dirty) && meta.invalid}
          helperText={((meta.touched || meta.dirty) && meta.invalid) ? getError(meta.error || meta.submitError) : null}
          value={input.value}
          onChange={(event: ChangeEvent<any>) => {
            onChange && onChange(event)
            input.onChange(event)
          }}
          onBlur={input.onBlur}
          onFocus={input.onFocus}
          name={input.name}
        >
          {children}
        </Input>
      )}
    </FinalField>
  )
}
