import React from 'react'
import { TextField as MUITextField, TextFieldProps } from '@material-ui/core'

export const TextField = ({
  variant='filled',
  startAdornment,
  InputProps,
  ...props
}: TextFieldProps) =>
  (
    <MUITextField
      {...props}
      InputProps={{
        startAdornment,
        ...InputProps,
      }}
      variant={variant}
    />
  )
