import React, { Component } from 'react';
import { assoc } from 'ramda';
import { Form as FinalForm } from 'react-final-form';
import { SubmissionErrors, FormApi } from 'final-form';
import { ObjectSchema, ValidationError } from 'yup'
import { FormRenderProps } from 'react-final-form'

import { Error } from './field'

interface OwnProps<T extends object> {
  onSubmit: (values: T, formApi: FormApi<T>) => Promise<SubmissionErrors | undefined>;
  initialValues: T;
  render: (renderProps: FormRenderProps<T>) => Component | JSX.Element;
  validationSchema: ObjectSchema<T>;
}

interface Errors {
  [key: string]: Error;
}

type Props<P extends object> = OwnProps<P>;

export const Form = <Values extends object>({
  onSubmit,
  initialValues,
  render,
  validationSchema
}: Props<Values>) => {
  const reduceErrors = (errors: Errors, yupError: ValidationError) =>
    assoc(yupError.path, yupError.message, errors)

  const yupErrorsToObject = (errors: ValidationError[]) => errors.reduce(reduceErrors, {})

  const handleValidation = (values: object) => validationSchema && validationSchema
    .validate(values, { abortEarly: false })
    .then(() => undefined)
    .catch(({ inner }: ValidationError) => yupErrorsToObject(inner))

  return (
    <FinalForm
      onSubmit={onSubmit}
      validate={handleValidation}
      initialValues={initialValues}
      render={render}
    />
  )
}

