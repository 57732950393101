import React from 'react'
import { Button, CircularProgress, makeStyles, Theme } from '@material-ui/core'
import { ButtonProps } from '@material-ui/core/Button/Button'

const useStyles = makeStyles((theme: Theme) => ({
  wrapper: {
    display: 'flex',
    justifyContent: 'center',
    position: 'relative',
    margin: theme.spacing(1),
  },
  buttonProgress: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
}));

interface OwnProps {
  progress?: boolean;
}

type Props = OwnProps & ButtonProps

export const ProgressButton = ({ children, progress = false, ...props }: Props) => {
  const { buttonProgress, wrapper } = useStyles()

  return (
    <div className={wrapper}>
      <Button {...props}>
        {children}
      </Button>
      {progress && <CircularProgress size={24} color='secondary' className={buttonProgress} />}
    </div>
  )
}
